<template>
  <div class="InfoMsettings-box">
    <div class="InfoMsettings-message" v-for="(item,i) in storelist" :key="i">
      <div class="Img-box">
        <img src="../../assets/img/tie.png" alt="" />
      </div>
      <div class="TitleImg-box">
        <img :src="item.logo" alt="" />
      </div>
      <div class="InfoMsettings-Content">
        <div class="InfoMsettings-Content-title">
          <span class="adress">{{item.shop_name}}</span>
          <img src="../../assets/img/date.png" alt="" />
          <span class="BusinessHours" v-if="item.flag_buz_24h == 1">营业时间：24小时营业</span>
          <span class="BusinessHours" v-else>
            营业时间:
            <span v-if="item.flag_buz_morning !== '0' ">早: {{item.times_morning_start}}-{{(item.times_morning_end)}}</span>
            <span v-if="item.flag_buz_noon !== '0' ">午: {{(item.times_noon_start)}}-{{(item.times_noon_end)}}</span>
            <span v-if="item.flag_buz_night !== '0' ">晚: {{(item.times_night_start)}}-{{(item.times_night_end)}}</span>
          </span>
        </div>
        <p class="Pass">
          <img src="../../assets/img/pass (1).png" alt="" /><span>已通过认证</span>
        </p>
        <div class="Personal">
          <span class="Grill">企业类型：{{item.shop_type_name}}</span>
          <span class="Area">店铺面积：{{item.area}}</span>
          <span class="Adress">店铺地址：{{item.province}}{{item.city}}{{item.district}}{{item.addr}}</span>
          <span class="User">联系人：{{item.contact}}</span>
          <span class="Phone">联系电话：{{item.mobile}}</span>
        </div>
        <p class="Synopsis">
          店铺简介：{{item.intro}}
        </p>
      </div>
      <div class="InfoMsettings-amend">
        <!-- EditorialEnterprise -->
        <img src="../../assets/img/xiu.png" alt="" @click="handleedit(item)" />
      </div>
    </div>
    <div class="InfoMsettings-Newstore" v-if="storelist.length <= 0">
      <p @click="Storeadd"><i class="el-icon-folder-add"></i> 新增门店</p>
    </div>
  </div>
</template>

<script>
import { query_shop_info_list } from "../../api/store"
  import SimpleDateFormat from "../fillter/filltertime"
export default {
  
  created () {
    this.dateSdf = new SimpleDateFormat("HH:mm");
  },
 
  data () {
    return {
      storelist: [],
      // dateSdf:''
    }
  },
  mounted () {
      console.log(this.$store.state.ent_id,'5646');
     
    if (this.$store.state.ent_id) {
      query_shop_info_list({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        console.log(res, 'sss');
        if (res.code == 200) {
          this.storelist = res.body.data
        }
        else {
          this.$message({
            message: '获取门店信息失败，请先登陆企业账号',
            type: 'error'
          })
        }
      })
    }else {
      this.$message({
        message: '请先选择企业如没有企业请先认证企业',
        type: 'error'
      })
    }
  },
  methods: {
    Storeadd () {
      this.$router.push({ name: 'EditorialEnterprise', params: { type: 'add' } })
    },
    handleedit (data) {
      this.$router.push({ name: 'EditorialEnterprise', query: { ent_id: data.ent_id, shop_id: data.shop_id, pageType: 'edit' } })
    }
  }
}
</script>

<style scoed lang="scss">
.el-main {
  
  background: #f2f6fc;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
}

.InfoMsettings-box {
  padding-bottom: 40px;
  .InfoMsettings-message {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    padding-bottom: 50px;
    background: #fff;
    border-radius: 10px;
    .Img-box {
      width: 40px;
      // flex: 1;
      height: 40px;
      margin: -11px 0 0 -9px;
    }
    .TitleImg-box {
      // flex: 2;
      width: 130px;
      height: 130px;
      margin-top: 35px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .InfoMsettings-Content {
      flex: 11;
      padding-left: 22px;
      .InfoMsettings-Content-title {
        padding: 57px 0 0 0;
        .adress {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
        img {
          vertical-align: middle;
          display: inline-block;
          margin: -10px 10px 0 30px;
          width: 20px;
          height: 20px;
        }
        .BusinessHours {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          span {
            margin-left: 20px;
          }
        }
      }
      .Pass {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #44cd68;
        padding: 15px 0 23px 0;
        img {
          vertical-align: middle;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .Personal {
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .Area {
          margin-left: 30px;
        }
        .Adress {
          margin-left: 30px;
        }
        .User {
          margin-left: 30px;
        }
        .Phone {
          margin-left: 30px;
        }
      }
      .Synopsis {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
        line-height: 30px;
      }
    }
    .InfoMsettings-amend {
      flex: 1;
      margin-top: 25px;
      img {
        vertical-align: right;
      }
    }
  }
  .InfoMsettings-Newstore {
    width: 100%;
    height: 276px;
    margin-top: 20px;
    border: 1px dashed #fc4a52;
    border-radius: 10px;
    text-align: center;
    line-height: 276px;
    p {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fc4a52;
    }
  }
}
</style>
